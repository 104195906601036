import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3100744155/src/autobackup-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "notification-groups"
    }}>{`Notification groups`}</h1>
    <p>{`AutoBackup sends notifications to inform about performed backup, replication and retention operations.
User can receive notifications through the following notification channels:`}</p>
    <ul>
      <li parentName="ul">{`E-mail`}</li>
      <li parentName="ul">{`Slack`}</li>
      <li parentName="ul">{`PagerDuty`}</li>
    </ul>
    <h2 {...{
      "id": "add-a-new-notification-group"
    }}>{`Add a new notification group`}</h2>
    <ol>
      <li parentName="ol">{`Choose "Settings" from the navigation bar.`}</li>
      <li parentName="ol">{`Choose "Notification Groups" tab and click "Create new" button.
`}<img alt="Empty notification groups list" src={require("../../assets/configure/notification-groups/notification-groups-list-1.png")} /></li>
      <li parentName="ol">{`Enter all required fields to the notification group form and click "Save" button.`}</li>
    </ol>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Notification group name`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Custom name of notification group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Notification type`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes  (at least one)`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Type of event after which user will be notified.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Notification frequency`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`How often notifications should be sent.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Notification channels`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes (at least one)`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Which channels should be used to inform user about an event.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`- Email`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Email address`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`- Slack channel name`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Name of the Slack channel`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`- Slack webhook URL`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`read more: `}<a parentName="td" {...{
              "href": "https://api.slack.com/messaging/webhooks"
            }}>{`Slack webhook`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`- PagerDuty routing key`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`read more: `}<a parentName="td" {...{
              "href": "https://v2.developer.pagerduty.com/docs/send-an-event-events-api-v2"
            }}>{`PagerDuty`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Excluded accounts`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`List of accounts for which notification will not be sent.`}</td>
        </tr>
      </tbody>
    </table>
    <p><img alt="Adding notification group" src={require("../../assets/configure/notification-groups/notification-groups-add.png")} /></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`The new notification group should be now visible on notification groups list.
`}<img alt="Notification groups list - added notification group" src={require("../../assets/configure/notification-groups/notification-groups-list-2.png")} /></li>
    </ol>
    <h2 {...{
      "id": "delete-notification-group"
    }}>{`Delete notification group`}</h2>
    <ol>
      <li parentName="ol">{`Choose "Settings" from the navigation bar.`}</li>
      <li parentName="ol">{`Choose "Notification Groups" tab.`}</li>
      <li parentName="ol">{`Click on "three dots" on the right side of the notification group you want to delete, and select "Delete" option.
`}<img alt="Deleting notification group" src={require("../../assets/configure/notification-groups/notification-groups-delete-1.png")} /></li>
      <li parentName="ol">{`Click on the "Delete" button on the confirmation window.
`}<img alt="Deleting notification groups - confirm deletion" src={require("../../assets/configure/notification-groups/notification-groups-delete-2.png")} /></li>
      <li parentName="ol">{`The notification group should disappear from notification groups list.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      